import React, { useEffect, useState } from "react"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css" // Import css alert
import { useParams } from "react-router-dom"
import { apiGet, apiPost } from "../../../../api"
import { REPORT_STATUS_LIST } from "../../../../global/data"
import { useFetch } from "../../../../hooks/useFetch"
import useUrl from "../../../../hooks/useUrl"
import { downloadBase64File } from "../../../../utils/downloadBase64"
import {
	Dropzone,
	Input,
	Modal,
	Pagination,
	Spinner,
} from "../../../components"
import Badge from "../../../components/Badge"
import { Blogging, IconDownload } from "../../../icons"

const ReportViewContainer = () => {
	const { reportId } = useParams()
	const { page } = useUrl()
	const [files, setFiles] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [showModalWarning, setShowModalWarning] = useState(false)
	const [isUpload, setIsUpload] = useState(false)
	const [triggerWarningData, setTriggerWarningData] = useState("")
	// const [checkOnChecking, setCheckOnChecking] = useState(false)
	// const [incomingNum, setIncomingNum] = useState("")
	const [errObj, setErrObj] = useState({})
	// const [yearReportUpload, setYearReportUpload] = useState(
	// 	new Date().getFullYear()
	// )

	const { response, loading, fetchData: refreshReportInstance } = useFetch({
		url: `/report-instance?report_id=${reportId}&page=${page || 1}&limit=10&sort=-createdDate`,
	})

	const { response: getReportData } = useFetch({
		url: `/report/view?id=${reportId}`,
	})

	const openModal = () => setShowModal(true)
	const closeModal = () => setShowModal(false)
	const openModalWarning = () => setShowModalWarning(true)
	const closeModalWarning = () => setShowModalWarning(false)

	const reportUploaded = text => {
		confirmAlert({
			message: text,
			buttons: [
				{
					label: "Ок",
					onClick: () => {},
				},
			],
		})
	}

	// const emptyIncomingNum = () => {
	// 	confirmAlert({
	// 		message:
	// 			"Пожалуйста введите входящий номер или установите отчет как отчет на проверку",
	// 		buttons: [
	// 			{
	// 				label: "Ок",
	// 				onClick: () => {},
	// 			},
	// 		],
	// 	})
	// }

	// const incNumInvalidAlert = () => {
	// 	confirmAlert({
	// 		message:
	// 			"Если вы отправляете отчёт на проверку укажите это с помощью галочки, поле с номером оставить пустым",
	// 		buttons: [
	// 			{
	// 				label: "Ок",
	// 				onClick: () => {},
	// 			},
	// 		],
	// 	})
	// }

	// const wrangNum = text => {
	// 	confirmAlert({
	// 		message: (
	// 			<div
	// 				style={{ whiteSpace: "pre-line" }}
	// 				dangerouslySetInnerHTML={{ __html: text }}
	// 			></div>
	// 		),
	// 		buttons: [
	// 			{
	// 				label: "Ок",
	// 				onClick: () => {},
	// 			},
	// 		],
	// 	})
	// }

	/**
	 * Загрузка файла отета
	 */
	const uploadReport = () => {
		// if (checkOnChecking == false && incomingNum == "") {
		// 	closeModal()
		// 	emptyIncomingNum()
		// 	return
		// }

		// if (incomingNum.length === 8) {
		// 	let newIncNum = incomingNum.split(/-|_/)
		// 	let validIncNum = newIncNum[newIncNum.length - 1].replace(/0/g, "")

		// 	if (validIncNum == "") {
		// 		closeModal()
		// 		incNumInvalidAlert()
		// 		return
		// 	}

		// 	let checkNum = newIncNum[newIncNum.length - 1] + ""
		// 	let newCheckNum = ""
		// 	for (let i = 0; i < checkNum.length; i++) {
		// 		if (checkNum[i] != 0) {
		// 			newCheckNum = checkNum.substr(i) + ""
		// 			break
		// 		} else newCheckNum = checkNum.substr(i + 1) + ""
		// 	}

		// 	if (newCheckNum < 1000000) {
		// 		closeModal()
		// 		wrangNum(
		// 			"Входящий номер некорректный! </br> Введите входящий номер присвоенный ЦБ"
		// 		)
		// 		return
		// 	}
		// }
		// if (checkOnChecking == true) {
		// 	setIncomingNum("")
		// }
		// if (checkOnChecking == false && incomingNum.length < 8) {
		// 	closeModal()
		// 	wrangNum("Количество чисел должно быть 8")
		// 	return
		// }
		if (!isUpload) {
			setIsUpload(true)

			const postData = new FormData()
			// !checkOnChecking &&
			// 	postData.append(
			// 		"incomingNumber",
			// 		`ТР-${yearReportUpload}-${incomingNum}`
			// 	)
			postData.append("file", files[0])

			apiPost({ url: `/report/upload?id=${reportId}`, postData })
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						console.error("Загрузка файла отета")
						return
					}
					setErrObj({})
					reportUploaded("Документ успешно загружен!")
					// setIncomingNum("")
					setFiles([])
					refreshReportInstance()
					closeModal()
				})
				.finally(() => setIsUpload(false))
		}
	}

	/**
	 * Скачивание документ
	 * @param {number} id - id отчета
	 */
	const downloadDocument = id => {
		apiGet({ url: `/report-instance/download?id=${id}` }).then(res => {
			if (res.error) {
				console.error("Скачивание документа")
				return
			}
			downloadBase64File(res.data.asBase64, res.data.name)
		})
	}

	/**
	 * Удаление файла
	 * @param {string} fileName - название файла
	 */
	const onFileRemove = fileName => {
		setFiles(files.filter(f => f.name !== fileName))
	}

	//
	const openTriggetWarning = id => {
		apiGet({ url: `/report-instance/triggered?id=${id}` }).then(res => {
			if (res.error) {
				console.error("Скачивание документа")
				return
			}
			setTriggerWarningData(res.data)
			openModalWarning()
		})
	}

	/**
	 * Добавление файла
	 * @param {file} filesData - файл
	 */
	const onFileUpload = filesData => {
		setFiles(filesData)
	}

	// const funcIncomingNum = e => {
	// 	setErrObj({})
	// 	if (e.length >= 9) {
	// 		setErrObj({ incNum: "Максимальное количество  цифр - 8" })
	// 		return
	// 	}
	// 	setIncomingNum(e)
	// }

	useEffect(() => {
		setErrObj({})
	}, [files])

	if (loading) {
		return (
			<div className="d-flex jc-center">
				<Spinner />
			</div>
		)
	}

	return (
		<div className="mfo-reports-examples">
			<div className="card ">
				<div className="card-header d-flex">
					<div className="name">Загруженные отчеты</div>
					<div
						id="close-modal-examples"
						className="close-modal close-modal-mfo-examples"
					>
						<img src="/static/img/icon/cancel.svg" alt="" />
					</div>
				</div>
				<div className="card-content">
					<div className="row">
						<div className="col-12 col-md-6">
							<Input
								readOnly
								label="Название отчета"
								defaultValue={getReportData?.template?.name}
							/>
						</div>
						<div className="col-12 col-md-6">
							<Input
								readOnly
								label="Период отчета"
								value={`${getReportData?.period?.startDate} - ${getReportData?.period?.endDate}`}
							/>
						</div>
					</div>

					{getReportData && !getReportData?.isBlockUpload && <button className="btn btn-primary mb-4 upload" onClick={openModal}>
						Загрузить отчет
					</button>}

					<div className="scroll-x">
						<table>
							<thead>
								<tr>
									<th className="example">#</th>
									<th className="date-table">
										Дата загрузки{" "}
										<img
											className="filtration-img"
											src="/static/img/icon/arrow-down-filtration.svg"
											alt=""
										/>
									</th>
									<th>Входящий номер</th>
									<th>Актуальность</th>
									<th className="status status-btn">
										Статус
										<img
											className="status-img ml-1"
											src="/static/img/icon/arrow-down-filtration.svg"
											alt=""
										/>
									</th>
									{getReportData?.limitLogicEnable ? <th>Превышение лимита</th> : <></>}
									<th className="edit"></th>
								</tr>
							</thead>

							<tbody>
								{response?.models?.map(i => {
									const {
										id,
										statusText,
										incomingNumber,
										createdDate,
										status,
										isActual,
										showTriggerDetailButton,
									} = i

									const { type } = REPORT_STATUS_LIST[+status - 1]
									return (
										<tr key={id}>
											<td className="example">{id}</td>
											<td className="date">{createdDate}</td>
											<td>{incomingNumber || ""}</td>
											<td>
												<input
													type="checkbox"
													className="checkbox"
													checked={isActual}
													onClick={e => e}
													onChange={e => e}
												/>
											</td>
											<td className="status">
												<Badge type={type}>{statusText}</Badge>
											</td>
											{getReportData?.limitLogicEnable
												? (<td>{i.limitExceeded
													? <div className={'badge badge-red'}>Лимит превышен</div>
													: (i.limitExceeded !== null
														? <div className={'badge badge-green'}>Лимит не превышен</div>
														: <></>)}
												</td>)
												: <></>}
											<td className="edit d-flex">
												<div className="col-auto">
													<button
														className="btn btn-primary"
														onClick={() => downloadDocument(id)}
														title="Скачать документ"
													>
														<IconDownload size={20} color="#fff" />
													</button>
												</div>
												{showTriggerDetailButton && (
													<div className="col-auto">
														<button
															className="btn btn-warning"
															onClick={() => openTriggetWarning(id)}
															title="Ошибки"
														>
															<Blogging size={20} color="#fff" />
														</button>
													</div>
												)}
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					<div className="mt-2 ta-center">
						<Pagination lastPage={response?.lastPage} page={page} />
						{/* <ReactPaginate
							pageCount={response?.lastPage == 1 ? 0 : response?.lastPage}
							pageRangeDisplayed={5}
							marginPagesDisplayed={3}
							onPageChange={e => setPage(e.selected + 1)}
							activeClassName={"_active"}
							containerClassName={"pagination d-inline-flex ta-center"}
							pageLinkClassName={"link"}
							pageClassName={"link"}
							previousLabel={false}
							nextLabel={false}
						/> */}
					</div>
				</div>
			</div>

			<Modal
				show={showModal}
				onClose={closeModal}
				modalStyles={{ maxWidth: 600 }}
			>
				{/* <div className="col-auto mb-3" style={{ display: "inline-block" }}>
					<label className="d-flex">
						<input
							type="checkbox"
							className="checkbox mr-2"
							checked={checkOnChecking}
							onChange={() => setCheckOnChecking(!checkOnChecking)}
						/>
						<span>Отправка отчета на проверку</span>
					</label>
				</div> */}
				{/* {!checkOnChecking && (
					<>
						<div className="d-flex">
							<span className="mt-auto mb-auto">ТР-</span>
							<Select
								fieldClasses="mt-auto mb-auto"
								onChange={e => setYearReportUpload(e.target.value)}
								value={yearReportUpload}
								values={[
									{
										value: new Date().getFullYear() - 1,
										label: new Date().getFullYear() - 1,
									},
									{
										value: new Date().getFullYear(),
										label: new Date().getFullYear(),
									},
									{
										value: new Date().getFullYear() + 1,
										label: new Date().getFullYear() + 1,
									},
								]}
							/>
							<span className="mt-auto mb-auto">-</span>
							<Input
								fieldClasses="mt-auto mb-auto"
								value={incomingNum}
								onChange={e => funcIncomingNum(e.target.value)}
							/>
						</div>
						{errObj.incNum && <span className="_error">{errObj.incNum}</span>}
					</>
				)} */}
				<div className="mt-3">
					<Dropzone
						maxSize={100000000}
						files={files}
						onFileRemove={onFileRemove}
						onFileUpload={onFileUpload}
						multiple={false}
						error={errObj.file}
					/>
					{errObj.file &&
						errObj.file.map(i => (
							<div key={i} className="_error">
								{i}
							</div>
						))}
				</div>

				<button className="mt-4 btn btn-primary" onClick={() => uploadReport()}>
					Загрузить отчёт
				</button>
			</Modal>
			<Modal
				show={showModalWarning}
				onClose={closeModalWarning}
				modalStyles={{ maxWidth: 600 }}
			>
				<div
					style={{ whiteSpace: "pre-line" }}
					dangerouslySetInnerHTML={{ __html: triggerWarningData }}
				></div>
			</Modal>
		</div>
	)
}

export default ReportViewContainer
